import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import logo from "../Assets/VINCC1-COR-PNG.png";

const Footer = () => {
  return (
    <div id="foot">
    <div className="footer-wrapper">
      
      <div className="footer-section-one">
      <div className="footer-section-columns">
          
          <span> <img src={logo} alt=''/></span>
          <span>(99) 8108-5050 <p>TV. Frederico Leda, 1725, Cohab - Bacabal/MA</p> </span>
          
          
        </div>
        </div>
        <div className="footer-section-center">
          <div className="footer-section-columns">
          <span></span>
          <span></span>
          <span></span>
            <span>Copyright 2024 © Vincc1 Tecnologia e Inovação LTDA</span>
           
          </div>
        </div>
        <div className="footer-section-two">
        <div className="footer-section-columns">
          
                   
        <span></span>
          <span></span>
          <div className="footer-icons-two" id="footer-icons">
        
         <a href="https://www.instagram.com/vincc1tic" target="_blank" rel="noreferrer"><FaInstagram /></a>
         <a href="https://wa.me/559981242197" target="_blank" rel="noreferrer"><FaWhatsapp /></a>
         <a href="mailto:contato@elegemais.com.br" target="_blank" rel="noreferrer"><HiOutlineMail /></a>
         </div>
          
        </div>
        
        
      </div>
    
    </div>
    </div>
  );
};

export default Footer;